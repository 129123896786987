import { CommonModule } from '@angular/common';
import { Component, OnDestroy, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { Subject, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CoreModule } from 'src/app/core/core.module';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { UserApiService } from 'src/app/core/services/http/user-api.service';

@Component({
  selector: 'app-logout-countdown',
  standalone: true,
  imports: [
    CoreModule,
    CommonModule,
    MatDialogContent,
    MatDialogClose,
  ],
  templateUrl: './logout-countdown.component.html'
})
export class LogoutCountdownComponent implements OnDestroy {
  readonly dialogRef = inject(MatDialogRef<LogoutCountdownComponent>);
  private destroyed$ = new Subject<void>();
  public data = inject<number>(MAT_DIALOG_DATA);
  countdownTimeLeft = this.data ? this.data * 60 : 3 * 60;  //time remaining in seconds
  logoutMessage = 'You have been inactive for a while. Would you like to remain logged in?';

  constructor(
    private auth: AuthService,
    private userApi: UserApiService
  ) {
    interval(1000)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.countdownTimeLeft--;
      });

    // If dialog is closed by any means, it's implied that the user is still there to interact & thus extend the expiration by making a dummy API call, which should refresh the timer.portal cookie
    // Although this would only work when at least 15min has passed from the session duration
    // TODO: Could use a proper designated call
    this.dialogRef.afterClosed().subscribe({
      next: () => {
        this.userApi.getIsArcUser().subscribe();
      }
    });
  }

  ngOnDestroy(): void {
    this.countdownTimeLeft = 0;
    this.destroyed$.next();
  }

  /** Logs out the user */
  logout(): void {
    this.destroyed$.next();
    this.auth.logout();
  }

  refreshToken(): void {
    this.dialogRef.close();
  }
}
